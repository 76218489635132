<template>
  <v-container fluid>

    <card :title="$t('models.user.selves')">
      <template v-slot:actions>
        <v-btn small color="primary" @click="createUser()"><v-icon left small>fa fa-plus</v-icon> {{ $t('actions.create') }}</v-btn>
      </template>
      <grid-view ref="grid" :headers="headers" endpoint="/user" model="user">
        <template v-slot:item._actions="{ item }">
          <v-btn @click="viewUser(item)" icon small><v-icon small>mdi-eye</v-icon></v-btn>
          <v-btn @click="editUser(item)" icon small><v-icon small>mdi-pencil</v-icon></v-btn>
          <v-btn @click="deleteUser(item)" icon small><v-icon x-small>fa fa-trash</v-icon></v-btn>
        </template>
      </grid-view>
    </card>

    <!-- view dialog -->
    <v-dialog v-model="viewDialog" max-width="600">
      <v-card v-if="user">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              {{user.name}}
            </h3>
            <div>{{user}}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn flat color="primary">Close</v-btn>
          <v-btn flat color="primary">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="600">
       <Form :userId="editedUserId" v-if="editDialog" @save="onDialogSave" />
    </v-dialog>

  </v-container>
</template>


<script>
import { Vue, Component } from "vue-property-decorator";
import Form from "@/pages/user/Form";
import {setPageTitle} from "@/utils/meta";
import Card from "@/components/Card";
import GridView from "@/components/GridView";

@Component({ components: {GridView, Card, Form } })
export default class UserIndex extends Vue {
  viewDialog = false;
  editDialog = false;
  user = null;
  editedUserId = null;

  created() {
    setPageTitle(this.$i18n.t('models.user.selves'));
  }

  get headers() {
    return [
      { text: '', value: '_actions', },
      { text: this.$i18n.t('models.user.name'), value: 'name', },
      { text: this.$i18n.t('models.user.email'), value: 'email', },
      { text: this.$i18n.t('models.user.phone'), value: 'phone', },
      { text: this.$i18n.t('models.user.role'), value: 'role', },
      { text: this.$i18n.t('models.user.status'), value: 'status', },
    ];
  }

  viewUser(user) {
    this.$api.get( `user/${user.id}` ).then(res => {
      this.user = res.data;
      this.viewDialog = true;
    });
  }

  createUser() {
    this.editedUserId = null;
    this.editDialog = true;
  }

  editUser(user) {
    this.editedUserId = user.id;
    this.editDialog = true;
  }

  deleteUser(user) {
    this.$root.$confirm.open('Delete user', 'Are you sure you want to delete this user ?', {}).then((confirm) => {
      if (confirm) {
        this.$api.delete(`user/${user.id}`).then(() => {
          this.$refs.grid.getModels();
        }).catch(() => {
          alert("Unable to remove user");
        });
      }
    });
  }

  onDialogSave(user) {
    this.$refs.grid.getModels(user.id);
    this.editDialog = false;
    this.editedUserId = null;
  }

}
</script>
